const GiftBRIData = [
  {
    id: 1,
    name: "HASBI ASHSHIDIEQY",
    number: "359901037337533",
  },
  {
    id: 2,
    name: "Ina Khaerunisa",
    number: "426101013858533",
  },
];
const GiftPermataData = [
  {
    id: 1,
    name: "Ina Khaerunisa",
    number: "9933471605",
  },
];

const BankData = [
  {
    id: 1,
    image:
      "https://buatlogoonline.com/wp-content/uploads/2022/10/Logo-Bank-BRI.png",
  },
  {
    id: 2,
    image:
      "https://nos.jkt-1.neo.id/bucket-development/News/9859645e-370b-4795-b856-5f3da6932e76.png",
  },
];
export { GiftBRIData, GiftPermataData, BankData };
