import React, { useContext } from "react";
import "./Intro.css";
import boy from "../../img/image-double-header.png";
import { themeContext } from "../../Context";
import { useLocation } from "react-router";
const Intro = () => {
  // Transition
  const location = useLocation();

  const name = location?.state?.name;
  const transition = { duration: 2, type: "spring" };

  // context
  const theme = useContext(themeContext);
  const darkMode = theme?.state?.darkMode;

  return (
    <div className="" id="">
      {/* left name side */}
      <div className="flex flex-row">
        <div className="i-name">
          {/* yahan change hy darkmode ka */}
          <span style={{ color: darkMode ? "white" : "" }}>Moment Of</span>
          <span>Hasbi & Ina</span>
          <span>
            Kepada Yth. Bapak/Ibu/Sdr/i Nama Tamu di Tempat:{" "}
            <span className="font-bold">{name}</span>
          </span>
        </div>
        <div className="lg:mx-96 hidden md:block">
          <div className="">
            <img src={boy} alt="" className="w-96" transition={transition} />
          </div>
        </div>
      </div>
      {/* right image side */}
      <div className="mx-3 block md:hidden">
        <img src={boy} alt="" className="w-96" transition={transition} />
      </div>
    </div>
  );
};

export default Intro;
