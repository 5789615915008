import React, { useEffect, useRef, useState } from "react";
import "./Navbar.css";
import Audio from "../../assets/musicBG.mp3";

const Navbar = () => {
  const audioRef = useRef(null);
  const volume = 0.5; // Set volume within the 0.0 to 1.0 range
  const [isMobile, setIsMobile] = useState(false);
  const [audioPlayed, setAudioPlayed] = useState(false); // Track if audio has been played

  useEffect(() => {
    const checkIfMobile = () => {
      const userAgent =
        navigator?.userAgent || navigator.vendor || window?.opera;
      // Check if the user is on a mobile device
      if (/android|iPad|iPhone|iPod/.test(userAgent.toLowerCase())) {
        setIsMobile(true);
      }
    };

    checkIfMobile();

    const audio = audioRef.current;
    if (audio) {
      audio.volume = volume;

      // Play audio when the user interacts with the document
      const playAudio = () => {
        if (!audioPlayed) {
          audio
            .play()
            .then(() => {
              setAudioPlayed(true); // Set audio as played only after play is successful
            })
            .catch((err) => {
              console.error("Auto play prevented", err);
            });
        }
      };

      window.addEventListener("click", playAudio);
      window.addEventListener("touchstart", playAudio); // For mobile touch events

      // Cleanup event listeners on unmount
      return () => {
        window.removeEventListener("click", playAudio);
        window.removeEventListener("touchstart", playAudio);
      };
    }
  }, [audioPlayed, volume]);

  return (
    <>
      <audio ref={audioRef} autoPlay>
        <source src={Audio} type="audio/mp3" />
        Your browser does not support the audio tag.
      </audio>
      <div className="n-wrapper" id="Navbar">
        <div className="n-left">
          <p className="n-name">Wedding</p>
        </div>
      </div>
    </>
  );
};

export default Navbar;
