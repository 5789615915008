import axios from "axios";
import { config } from "../../config/api";
import { useEffect, useRef, useState } from "react";
import { message } from "antd";
import moment from "moment";
import "moment/locale/id"; // Import Indonesian locale

const FormComments = () => {
  const [Comments, setComments] = useState([]);
  const commentsRef = useRef(null);
  const [form, setForm] = useState({ name: "", descriptions: "" });
  const [error, setError] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
    setError("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validation check
    if (!form.name) {
      setError("Nama Harus Di Isi.");
      return;
    }
    if (!form.descriptions) {
      setError("Pesan Harus Di Isi.");
      return;
    }
    handleCreate(form);
  };
  const handleCreate = async (value) => {
    try {
      const response = await axios.post(
        `${config.URL}/api/v1/comments/create`,
        value
      );
      if (response.data.status === true) {
        message.success("Pesan Berhasil di simpan.");
        setForm({ name: "", descriptions: "" });
        handleGet();
      } else {
        message.error("An error occurred.");
      }
    } catch (error) {
      message.error(error.response?.data?.messages || "An error occurred.");
    }
  };
  const handleGet = async () => {
    try {
      const response = await axios.get(
        `${config.URL}/api/v1/comments/getting?size=10&page=1`
      );
      console.log(response.data);
      if (response.data.status === true) {
        setComments(response.data.data);
      } else {
      }
    } catch (error) {
      console.log(error);
      message.error(error.response?.data?.messages || "An error occurred.");
    }
  };

  useEffect(() => {
    moment.locale("id");

    if (commentsRef.current) {
      commentsRef.current.scrollTop = commentsRef.current.scrollHeight;
    }
    handleGet();
  }, []);

  return (
    <>
      <div
        className="p-4 rounded-lg shadow-lg bg-white text-[#6693b8] w-full h-full flex flex-col justify-between"
        style={{
          borderColor: "#6693b8",
          borderWidth: "6px",
          borderStyle: "solid",
        }}
      >
        <div
          className={`overflow-y-auto flex-grow p-2 ${
            Comments?.length > 3 ? "h-96" : "h-64"
          }`}
          ref={commentsRef} // Attach the ref here
        >
          {Comments.length > 0 ? (
            <>
              {Comments?.map((item, index) => (
                <div className="bg-white shadow-lg p-2 mb-2" key={index}>
                  <div className="justify-between items-start">
                    <div className="flex flex-col">
                      <p className="text-xs font-bold">{item?.name}</p>
                      <p className="text-xs text-black">{item?.descriptions}</p>
                    </div>
                    <div className="h-2" />
                    <p className="text-xs text-gray-500">
                      {moment(item?.created_at).format(
                        "MMMM, D, yyyy, h:mm:ss a"
                      )}
                    </p>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <p className="text-xs">Tidak ada komentar.</p>
          )}
        </div>
        <div className="mt-4">
          <form className="flex flex-col gap-4 p-4" onSubmit={handleSubmit}>
            <input
              type="text"
              name="name"
              onChange={handleChange}
              value={form.name}
              className="user p-2 border border-gray-300 rounded-md text-xs"
              placeholder="Nama"
            />
            <textarea
              name="descriptions"
              className="user p-2 border border-gray-300 rounded-md text-xs"
              placeholder="Pesan"
              onChange={handleChange}
              value={form.descriptions}
              rows="4"
            />
            {error && <p className="text-red-500 text-xs">{error}</p>}{" "}
            {/* Display error message */}
            <input
              type="submit"
              value="Send"
              className="button bg-[#6693b8] text-white p-2 rounded-md cursor-pointer hover:bg-[#557a99]"
            />
          </form>
        </div>
      </div>
    </>
  );
};

export default FormComments;
