import React, { useContext } from "react";
import "./Services.css";
import { themeContext } from "../../Context";

const Services = () => {
  // context
  const theme = useContext(themeContext);
  const darkMode = theme?.state?.darkMode;

  // transition
  const transition = {
    duration: 1,
    type: "spring",
  };

  return (
    <div className="services" id="services">
      {/* left side */}
      <div className="awesome">
        {/* dark mode */}
        <span style={{ color: darkMode ? "white" : "" }}>Ceremony Wedding</span>
        <span>Akad Nikah - Walimatul 'Urs (Resepsi)</span>
        <spane>
          Kami dengan penuh rasa syukur mengundang Anda untuk hadir dan
          memberikan doa restu pada acara pernikahan kami
        </spane>

        <a href="https://maps.app.goo.gl/v5Czwgo6QEx9L3eb7" target="_blank">
          <button className="button s-button">Lihat Lokasi</button>
        </a>
        <div className="blur s-blur1" style={{ background: "#ABF1FF94" }}></div>
      </div>
      <div className="mx-3" />
      <div
        className="p-4 rounded-lg shadow-lg bg-white text-[#6693b8] w-full animate-slide-fade animate-border-move"
        style={{
          borderColor: "#6693b8",
          borderWidth: "6px",
          borderStyle: "solid",
        }}
      >
        <div className="text-center">
          <p className="text-[#6693b8] text-3xl animate-text-fade">
            Sabtu, 28 Desember 2024
          </p>
          <div className="h-3" />
          <p className="text-[#6693b8] text-3xl animate-text-fade">
            Akad Nikah
          </p>
          <p className="text-[#6693b8] text-1xl animate-text-fade">
            08.00 s.d selesai
          </p>
          <p className="text-[#6693b8] text-3xl animate-text-fade">
            Walimatul 'Urs (Resepsi)
          </p>
          <p className="text-[#6693b8] text-1xl border-b-2 border-[#6693b8] animate-text-fade">
            11.00 s.d selesai
          </p>
          <div className="h-3" />
          <p className="text-[#6693b8] text-xs animate-text-fade">
            “Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan
            pasangan-pasangan untukmu dari jenismu sendiri, agar kamu cenderung
            dan merasa tenteram kepadanya, dan Dia menjadikan di antaramu rasa
            kasih dan sayang. Sungguh, pada yang demikian itu benar-benar
            terdapat tanda-tanda (kebesaran Allah) bagi kaum yang berpikir”
          </p>
          <div className="h-3" />
          <p className="text-[#6693b8] text-3xl animate-text-fade">
            (Q.S Ar Rum : 21)
          </p>
        </div>
      </div>

      <style jsx>{`
        @keyframes slideFade {
          0% {
            opacity: 0;
            transform: translateY(20px);
          }
          100% {
            opacity: 1;
            transform: translateY(0);
          }
        }

        @keyframes textFade {
          0% {
            opacity: 0;
            transform: translateY(10px);
          }
          100% {
            opacity: 1;
            transform: translateY(0);
          }
        }

        @keyframes borderMove {
          0% {
            transform: translateY(-10px);
          }
          50% {
            transform: translateY(10px);
          }
          100% {
            transform: translateY(-10px);
          }
        }

        .animate-slide-fade {
          animation: slideFade 1.2s ease-in-out;
        }

        .animate-text-fade {
          animation: textFade 1.5s ease-in-out;
        }

        .animate-border-move {
          animation: borderMove 2s infinite ease-in-out;
        }
      `}</style>
    </div>
  );
};

export default Services;
