import Navbar from "../components/Navbar/Navbar";
import Intro from "../components/Intro/Intro";
import Services from "../components/Services/Services";
import "../App.css";
import Footer from "../components/Footer/Footer";
import { useContext } from "react";
import { themeContext } from "../Context";
import Introductions from "../components/Introductions/Introductions";
import Gift from "../components/Gift/Gift";
import Wishes from "../components/Wishes/Wishes";
import Close from "../components/Close/Close";
function Home() {
  const theme = useContext(themeContext);
  const darkMode = theme?.state?.darkMode;
  return (
    <div
      className="App"
      style={{
        background: darkMode ? "black" : "",
        color: darkMode ? "white" : "",
      }}
    >
      <Navbar />
      <Intro />
      <Introductions />
      <Services />
      <Gift />
      <Wishes />
      <Close />
      <Footer />
    </div>
  );
}

export default Home;
