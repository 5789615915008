import React, { useContext } from "react";
// import "./Services.css";
import { themeContext } from "../../Context";
import FormComments from "../Form/Form.Comments";

const Wishes = () => {
  const theme = useContext(themeContext);
  const darkMode = theme?.state?.darkMode;
  // component
  const component = (
    <div className="awesome">
      <span style={{ color: darkMode ? "white" : "" }}>Wishes</span>
      <span>Harapan</span>
      <spane>
        Kepada Bapak/Ibu/Saudara/i yang ingin memberikan ucapan kepada kami
        dapat dituliskan di bawah ini
      </spane>

      <div className="blur s-blur1" style={{ background: "#ABF1FF94" }}></div>
    </div>
  );
  return (
    <>
      <div className="" id="services">
        <div className="">
          <div>{component}</div>
          <div className="h-3" />
          <div className="lg:w-96">
            <FormComments />
          </div>
        </div>
      </div>
      <div className="h-24" />
    </>
  );
};

export default Wishes;
