import React, { useContext } from "react";
import "./Intro.css";
import { themeContext } from "../../Context";
import { useNavigate, useParams } from "react-router-dom";
import Lottie from "lottie-react";
import GiftAnimation from "../../lottie/startCincin.json";
import start from "../../img/start.png";

const Start = () => {
  const { name } = useParams();
  const navigate = useNavigate();
  const theme = useContext(themeContext);
  const darkMode = theme?.state?.darkMode;
  const handleSwich = () => {
    navigate("/", { state: { name: name } });
  };
  return (
    <div className="start-container">
      <div className="i-name">
        {/* Text based on dark mode */}
        <span style={{ color: darkMode ? "white" : "" }}>Moment Of</span>
        <span>Hasbi & Ina</span>
        <span>
          Kepada Yth. Bapak/Ibu/Sdr/i Nama Tamu di Tempat:{" "}
          <span className="font-bold">{name}</span>
        </span>
        <div className="">
          <img src={start} alt="" className="w-96" />
        </div>
      </div>

      <div className="lottie-container">
        {/* Lottie animation */}
        <Lottie animationData={GiftAnimation} loop={true} className="lottie" />
      </div>

      {/* Centered button at the bottom */}
      <div className="button-container" onClick={handleSwich}>
        <button className="button s-button text-xs">Lihat Undangan</button>
      </div>
    </div>
  );
};

export default Start;
