import React, { useContext } from "react";
import Lottie from "lottie-react";
import { FaInstagram } from "react-icons/fa";
import "./Services.css";
import Card from "../Card/Card";
import HeartEmoji from "../../img/heartemoji.png";
import Glasses from "../../img/glasses.png";
import Humble from "../../img/humble.png";
import { themeContext } from "../../Context";
import { motion } from "framer-motion";
import Resume from "./resume.pdf";
import ina from "../../img/ina.png";
import hasbi from "../../img/hasbi.png";
import GiftAnimation from "../../lottie/flower.json";
const Introductions = () => {
  // context
  const theme = useContext(themeContext);
  const darkMode = theme?.state?.darkMode;

  // transition
  const transition = {
    duration: 1,
    type: "spring",
  };

  return (
    <div className="" id="">
      <div className="h-10" />
      <div className="awesome">
        <div
          className="rounded-lg text-white p-5 up-down-animation"
          style={{
            background: "#6693b8",
            textShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
            textAlign: "center",
          }}
        >
          <p style={{ color: "white", fontSize: "14px", textAlign: "center" }}>
            Tanpa mengurangi rasa hormat. Kami mengundang Bapak/Ibu/Saudara/i
            serta kerabat sekalian untuk menghadiri acara pernikahan kami :
          </p>
          <div className="h-10" />
          <div
            className="blur s-blur1"
            style={{
              background: "#ABF1FF94",
              color: "white",
              textAlign: "center",
            }}
          ></div>
          <div className="">
            <div className="text-center" style={{ color: "white" }}>
              <div>
                <div className="flex justify-between w-full">
                  <div className="hidden lg:w-full" />
                  <img src={hasbi} alt="" className="" width={200} />
                </div>
                <div className="flex justify-center items-center">
                  <span
                    style={{
                      textAlign: "center",
                      display: "flex",
                      alignItems: "center",
                      gap: "0.5rem",
                    }}
                  >
                    <p>Hasbi Ashshidieqy, S.Pd</p>{" "}
                    <a href="https://www.instagram.com/hasbi_ashshidieqy?igsh=M3pqbWF0Ym5sZmV1">
                      <FaInstagram className="mb-1" />
                    </a>
                  </span>
                </div>
                <div className="child">
                  <p>
                    Anak ke-3 dari Bapak Adang Taufiqulhuda dan Ibu Titi Suryati
                  </p>
                </div>
              </div>
              <div>
                <div className="flex justify-between w-full">
                  <div className="w-full" />
                  <img src={ina} alt="" className="" width={200} />
                </div>
                <div className="flex justify-center items-center">
                  <span
                    style={{
                      textAlign: "center",
                      display: "flex",
                      alignItems: "center",
                      gap: "0.5rem",
                    }}
                  >
                    <p>Ina Khaerunisa, S.Pd</p>{" "}
                    <a href="https://www.instagram.com/ina.khaerunisa?igsh=MW9objR0NWI0d243bg==">
                      <FaInstagram className="mb-1" />
                    </a>
                  </span>
                </div>
                <div className="child">
                  <p>
                    Anak ke-2 dari Bapak H. Keco Sukarsa, M.Pd.I dan Ibu Iis
                    Paijah
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <style jsx>{`
          .up-down-animation {
            animation: upDown 2s infinite ease-in-out;
          }

          @keyframes upDown {
            0% {
              transform: translateY(0);
            }
            50% {
              transform: translateY(-20px); /* Move up */
            }
            100% {
              transform: translateY(0); /* Move down */
            }
          }
        `}</style>
      </div>
      <div className="lg:w-1/4">
        <Lottie animationData={GiftAnimation} loop={true} />
      </div>
    </div>
  );
};

export default Introductions;
