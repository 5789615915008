import React, { useContext } from "react";
import { Routes, Route } from "react-router-dom";
import { themeContext } from "./Context";
import Home from "./pages/Home.jsx";
import Start from "./pages/Start/Start.jsx";

function App() {
  const theme = useContext(themeContext);
  const darkMode = theme?.state?.darkMode;

  return (
    <Routes>
      <Route
        path="/"
        element={
          <>
            <Home />
          </>
        }
      />
      <Route
        path="to/:name"
        element={
          <>
            <Start />
          </>
        }
      />
    </Routes>
  );
}

export default App;
